import axios from "axios";
import fileDownload from "js-file-download";

interface downloadObjectProps {
  downloadFile: any;
  materialId: string;
}

const downloadObject = async (props: downloadObjectProps) => {
  /**
   * To download a (small or large) file from S3 we need to create a pre-signed URL that will be valid for
   * 15 minutes (default time | you can change this). With this pre-signed URL we can download any item from
   * the S3 bucket without having to change the file permission.
   *
   * Why this way?
   * - direct download will have ugly file names (d6b57632_d33a_4183_9877_9b6e559e4baf.zip) Thats the filename on S3
   * - We need to make the whole S3 bucket public
   * - Otherwise no access control through lambda function
   *
   */

  const filename = props.downloadFile.url.split("/").slice(-1).pop();
  if (filename) {
    try {
      return await axios
        .post(
          `${process.env.GATSBY_IRIS_API}object/${props.materialId}/download`,
          {
            materialId: props.materialId,
            filename: filename,
          }
        )
        .then(async (response) => {
          // console.log("🚀 ~ response.data", response.data);
          // console.log("🚀 ~ props", props);

          // window.open(response.data, "_blank");

          // What will be the download filename:
          // 1 - downloadFilename
          // 2 - originalFileName
          // 3 - s3 bucket filename like `d7768071-b8b7-417d-8715-09169d29aa75.docx`
          const downloadFileName =
            props.downloadFile && props.downloadFile.downloadFilename
              ? props.downloadFile.downloadFilename
              : props.downloadFile && props.downloadFile.originalFileName
              ? props.downloadFile.originalFileName
              : filename;

          try {
            return await axios
              .get(response.data.body, {
                responseType: "blob",
              })
              .then((res) => {
                fileDownload(res.data, downloadFileName);
                return {
                  status: 200,
                  msg: "Download is starting",
                };
              });
          } catch (error) {
            return {
              status: 400,
              msg: "We are unable to download the file at the moment, please try again.",
            };

            /**
             * At this point we should send a slack message to our channel so we can investigate.
             */
          }
        })
        .catch(() => {
          return {
            status: 400,
            msg: "We are unable to download the file at the moment, please try again.",
          };

          /**
           * At this point we should send a slack message to our channel so we can investigate.
           */
        });
    } catch (error) {
      return {
        status: 400,
        msg: "We are unable to download the file at the moment, please try again.",
      };
    }
  } else {
    return {
      status: 400,
      msg: "We are unable to download the file at the moment, please try again.",
    };
  }
};

export default downloadObject;
