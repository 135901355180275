import React, { useContext, useEffect, useState } from "react";
import { isArray, isEmpty } from "lodash";
import { PageProps } from "gatsby";
import getApiData from "../../lib/getApiData";
import Layout from "../../components/layout";
import H1 from "../../components/htmlElements/h1";
import downloadObject from "../../lib/downloadObject";
import DetailsLeftColumnContent from "../../components/detailsElements/DetailsLeftColumnContent";
import DetailsRightColumnContent from "../../components/detailsElements/DetailsRightColumnContent";
import { AuthenticationState } from "../../context/AuthenticationContext";
import LoadingIcon from "../../components/loadingIcon";
import encryption from "../../helpers/encryption";

const messageMaterialNotAvailable = "Sorry this material is not available.";

export default function DetailsPage(props: PageProps) {
  // Extract variables from props
  const { id: materialId } = props;

  const authenticationState = useContext(AuthenticationState) || {
    token: "",
    isAuthenticated: false,
  };

  const { token, isAuthenticated } = authenticationState;

  const [loaded, setLoaded] = useState<boolean>(false);
  const [viewable, setViewable] = useState<boolean>(false);

  const [materialTitle, setMaterialTitle] = useState<string>("");

  let userRightsAndSettings = "";
  const [metadata, setMetadata] = useState<object>({});
  const [userRights, setUserRights] = useState<object>({});

  const openDownloadModal = async (
    incomingDownloadFile: any,
    callbackFunction?: Function
  ) => {
    if (process.env.NODE_ENV === "development") {
      console.log(`Open download modal`);
    }

    const response = await downloadObject({
      downloadFile: incomingDownloadFile,
      materialId,
    });

    if (response.status === 200 && callbackFunction) {
      callbackFunction(response);
    }
  };

  /**
   * Fetch material details (metadata)
   */
  useEffect(() => {
    (async () => {
      const details = await getApiData({
        endpoint: `materials/${materialId}`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "X-Amz-Security-Token": isAuthenticated ? token : "",
        },
      });

      if (details && details.status === 200) {
        setMetadata(details.data);
      }
    })();
  }, [materialId]);

  /**
   * Once metadata is loaded, fetch the material title if available.
   */
  useEffect(() => {
    if (!isEmpty(metadata)) {
      if (
        metadata &&
        metadata.materialMetadata &&
        metadata.materialMetadata.instrument &&
        isArray(metadata.materialMetadata.instrument.title)
      ) {
        if (
          typeof metadata.materialMetadata?.instrument?.title[0] === "object"
        ) {
          if (metadata.materialMetadata.instrument.title[0]["#text"]) {
            setMaterialTitle(
              metadata.materialMetadata.instrument.title[0]["#text"]
            );
          }
        } else {
          setMaterialTitle(metadata.materialMetadata?.instrument?.title[0]);
        }
      } else {
        // We don't have title in instrument. So load record details without it.
      }

      if (metadata) {
        if (metadata.userRights) {
          const password = `${materialId}-dti-2023`;
          userRightsAndSettings = encryption.decryptObject(
            metadata.userRights,
            password
          );
          setUserRights(userRightsAndSettings);

          if (metadata.objectMetadata.status === "published") {
            setViewable(true);
          } else {
            if (userRightsAndSettings.isAdminUser) {
              setViewable(true);
            }
            // Investigating why false for my record 0Zesr-Nmbyq
            if (userRightsAndSettings.isOwner) {
              setViewable(true);
            }
          }
        }
      }

      // When all done, set loaded to true
      setLoaded(true);
    }
  }, [metadata]);

  /**
   * Create the details content html
   */
  const detailsContent =
    loaded && viewable ? (
      <>
        <H1 innerContent={materialTitle} additionalClasses="mb-5" />

        {/* Keep this div here to fix a strange build/caching issue */}
        <div className="test" />

        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 order-2 md:col-span-4 md:order-1">
            <DetailsLeftColumnContent
              materialId={materialId}
              filesMetadata={metadata?.filesMetadata || []}
              doiReference={metadata?.objectMetadata?.doiReference}
              openDownloadModal={openDownloadModal}
              userRightsAndSettings={userRights}
            />
          </div>

          <div className="col-span-12 order-1 md:col-span-8 md:order-2">
            <DetailsRightColumnContent
              materialMetadata={metadata.materialMetadata}
            />
          </div>
        </div>
      </>
    ) : (
      ""
    );

  /**
   * Loading content
   */
  const loadingContent = (
    <div className="col-span-12 text-center">
      <LoadingIcon />
    </div>
  );

  const viewableContent = viewable
    ? detailsContent
    : messageMaterialNotAvailable;

  /**
   * Show the loading icon by default, once the metadata is loaded we can show the details content.
   */
  return <Layout>{loaded ? viewableContent : loadingContent}</Layout>;
}
