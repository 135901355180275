import React from "react";

import TitleTag from "./TitleTag";
import ListTag from "./ListTag";

interface CreateListProps {
  value: object;
  title?: string;
  showTitle?: boolean;
}

const CreateText = (props: CreateListProps) => {
  let value = "";
  if (props.value) {
    if (typeof props.value !== "object") {
      value = props.value;
    } else if (props.value["#text"]) {
      value = props.value["#text"];
    } else {
      //
    }
  }
  const title = props.showTitle ? <TitleTag>{props.title}</TitleTag> : "";
  return (
    <>
      {title}
      <ListTag>{value}</ListTag>
      <br />
    </>
  );
};

export default CreateText;
