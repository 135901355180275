import React, { useContext, useEffect, useState } from "react";
import H4 from "../htmlElements/h4";
import Button from "../htmlElements/button";
import { AuthenticationState } from "../../context/AuthenticationContext";
import { validateToken } from "../../lib/functions";

interface Props {
  materialId: string;
  userRightsAndSettings: Object;
}

export default function DetailsLeftColumnAdminActions(props: Props) {
  const authenticationState = useContext(AuthenticationState) || {
    token: "",
  };

  const { token } = authenticationState;

  const { materialId, userRightsAndSettings } = props;

  const [hasValidToken, setHasValidToken] = useState(false);

  /**
   * Verify the token before showing the admin menu
   */
  useEffect(() => {
    (async () => {
      if (token) {
        const validateTokenResponse =
          (await validateToken(token, false)) || false;

        setHasValidToken(validateTokenResponse);
      }
    })();
  }, []);

  return materialId ? (
    // <div className="pt-5 mt-0 border-t border-t-gray-2">
    <div className="pt-5 mt-0">
      <div className="pb-5">
        <Button
          innerContent="Submit a similar material"
          color="blue"
          link
          href={`/submit/similar/${materialId}`}
        />
      </div>

      {hasValidToken &&
      (userRightsAndSettings.isAdminUser || userRightsAndSettings.isOwner) ? (
        <div className="bg-white rounded-lg shadow mx-auto sm:overflow-hidden sticky top-5 pt-3 pb-5 px-5">
          <H4 innerContent="Admin menu" additionalClasses="pb-3" />

          <span className="block mb-1 text-xs">
            Note: Only visible when admin/owner is logged in.
          </span>

          <div className="pt-3">
            <Button
              innerContent="Edit material"
              color="blue"
              link
              href={`/submit/${materialId}/1`}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  ) : (
    ""
  );
}
