import React, { useState } from "react";

import H2 from "../htmlElements/h2";
import Button from "../htmlElements/button";
import DetailsLeftColumnAdminActions from "./AdminActions";
import CopyToClibboardIcon from "../htmlElements/copyToClibboardIcon";

function DownloadFilesButtons(props: any) {
  const { downloadFiles, openDownloadModal } = props;

  const downloadButtons = [];

  Object.keys(downloadFiles).forEach((key) => {
    const downloadFile = downloadFiles[key];

    const { downloadFilename, url } = downloadFile;

    const defaultInnerContent = `Download file '${downloadFilename}'`;
    const [innerContent, setInnerContent] = useState(defaultInnerContent);

    let materialId = "";
    let fileExtension = "";
    if (url) {
      materialId = url
        .replace(
          "https://uk-ac-york-dti-iris-files-bucket-staging.s3.eu-west-1.amazonaws.com/",
          ""
        )
        .replace(
          "https://uk-ac-york-dti-iris-files-bucket-production.s3.eu-west-1.amazonaws.com/",
          ""
        );
      const splitUrl = url.split(".");
      fileExtension = splitUrl[splitUrl.length - 1];
    }

    const resetInnerContent = (response: any) => {
      setInnerContent(defaultInnerContent);
    };

    const downloadButton = (
      <li className="pb-5" key={downloadFilename || url}>
        <Button
          innerContent={innerContent}
          additionalClasses="download-button"
          color="blue"
          fullWidth
          textSize="xs"
          onClick={() => {
            if (window.gtag) {
              const fileDownloadEventParameters = {
                file_extension: fileExtension,
                file_name: downloadFilename,
                link_classes: "",
                link_domain: "",
                link_id: materialId,
                link_text: downloadFilename,
                link_url: url,
              };

              window.gtag(
                "event",
                "file_download",
                fileDownloadEventParameters
              );
            }

            setInnerContent(`Start downloading '${downloadFilename}'`);
            openDownloadModal(downloadFile, resetInnerContent);
          }}
        />
      </li>
    );

    downloadButtons.push(downloadButton);
  });

  return (
    <div className="pb-5 border-b border-b-gray-2 mb-5 download-button">
      <ul>{downloadButtons}</ul>
    </div>
  );
}

interface DetailsLeftColumnContentsProps {
  materialId: string;
  filesMetadata: object;
  doiReference: string;
  openDownloadModal: unknown;
  userRightsAndSettings: object;
}

export default function DetailsLeftColumnContent(
  props: DetailsLeftColumnContentsProps
) {
  const {
    materialId,
    filesMetadata,
    doiReference,
    openDownloadModal,
    userRightsAndSettings,
  } = props;

  const downloadButtons = (
    <DownloadFilesButtons
      downloadFiles={filesMetadata}
      openDownloadModal={openDownloadModal}
    />
  );

  return (
    <>
      {/* <div className="grid grid-cols-2 gap-4 pb-5">
        <div className="col-span-1 p-5 text-center h-[200px] bg-blue-light">
          Image
        </div>

        <div className="col-span-1 p-5 text-center h-[200px] bg-blue-light">
          Image
        </div>

        <div className="col-span-1 p-5 text-center h-[200px] bg-blue-light">
          Image
        </div>

        <div className="col-span-1 p-5 text-center h-[200px] bg-blue-light">
          Image
        </div>
      </div> */}
      {/* <div className="pb-5">
        <Button
          innerContent="Download all material(s)"
          color="blue"
          fullWidth={true}
          additionalClasses="mb-5"
          onClick={() => openDownloadModal("all")}
        />
      </div>

      <span className="text-center block pb-5">or</span> */}

      <H2 innerContent="Download materials" additionalClasses="pb-5" />

      {downloadButtons}

      <div className="pb-5 border-b border-b-gray-2 mb-5">
        <H2 innerContent="Licence Information" />
        <p className="text-sm">
          <a href="http://creativecommons.org/licenses/by-nc-sa/3.0/">
            <img
              src="/images/by-nc-sa.png"
              width="68"
              height="25"
              alt="This item is licensed under a Creative Commons Attribution NonCommercial ShareAlike (CC BY-NC-SA 3.0) licence."
              title="This item is licensed under a Creative Commons Attribution NonCommercial ShareAlike (CC BY-NC-SA 3.0) licence."
              style={{ float: `left`, padding: `6px 10px 6px 0px` }}
            />
          </a>
          You can remix, tweak, and build upon this work non-commercially, as
          long as you credit the creators of the instrument and license your new
          creations under identical terms.
        </p>
      </div>

      {doiReference ? (
        <div className="pb-5 mb-5 relative">
          <H2
            innerContent="How to cite this material"
            additionalClasses="mb-2"
          />
          <div className="text-sm">
            <div
              className="markdownText"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: doiReference,
              }}
            />
          </div>
          <div className="absolute right-2 top-1">
            <CopyToClibboardIcon text={doiReference} />
          </div>
        </div>
      ) : (
        ""
      )}

      {/* <div className="grid grid-cols-2 gap-4 pb-5 border-b border-b-gray-2 mb-5">
        <div className="">
          <Button
            innerContent="Leave feedback"
            color="blue"
            fullWidth={true}
            onClick={() => props.openFeedbackModal()}
          />
        </div>
        <div className="">
          <Button
            innerContent="Email to friend"
            color="blue"
            fullWidth={true}
            additionalClasses="inline-block"
            onClick={() => props.openEmailModal()}
          />
        </div>
      </div> */}

      <DetailsLeftColumnAdminActions
        materialId={materialId}
        userRightsAndSettings={userRightsAndSettings}
      />
    </>
  );
}
