/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
/* eslint-disable no-plusplus */
import React from "react";
import { Link } from "gatsby";
import { v4 as uuidv4 } from "uuid";

import clsx from "clsx";
import TitleTag from "./TitleTag";
import ListTag from "./ListTag";
import { stringToClassName } from "../../lib/functions";

interface CreateListProps {
  items: any;
  returnFieldName?: string;
  title?: string;
  showTitle?: boolean;
  noLink?: boolean;
}

function CreateList(props: CreateListProps) {
  const list = [];

  const { items, returnFieldName, showTitle, title, noLink } = props;

  try {
    const itemsArray = typeof items !== "object" ? [items] : items;

    for (let index = 0; index < itemsArray.length; index++) {
      const value = returnFieldName
        ? itemsArray[index][returnFieldName]
        : itemsArray[index];

      let newValue = "";
      if (typeof value === "object") {
        newValue = value["#text"] ? value["#text"] : "";
      } else {
        newValue = value;
      }

      // const newValue = value;

      if (newValue) {
        const link = noLink ? (
          newValue
        ) : (
          <Link to={`/search/?q=${newValue}`}>{newValue}</Link>
        );
        const listOption = <li key={uuidv4()}>{link}</li>;

        // @ts-ignore
        list.push(listOption);
      }
    }
  } catch (error) {}

  const titleDisplay = showTitle ? <TitleTag>{title}</TitleTag> : "";

  return list.length > 0 ? (
    <div className={clsx("listView", stringToClassName(title || ""))}>
      {titleDisplay}
      <ListTag>{list}</ListTag>
      <br />
    </div>
  ) : null;
}

export default CreateList;
