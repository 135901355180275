import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import { isEmpty } from "lodash";
import H2 from "../htmlElements/h2";
import { createAPAReference } from "../../helpers/createAPAReference";
import { DictionaryDataState } from "../../context/DictionaryDataContext";

function ReferencesForPublications(props: any) {
  const [references, setReference] = useState([]);
  const dictionaryDataState = useContext(DictionaryDataState) || [];

  // Another implementation of useEffect with resolved ESlint react-hooks/exhaustive-depth https://github.com/facebook/react/issues/14920
  // Construct Apa references for all publications in an async function
  // https://devtrium.com/posts/async-functions-useeffect and https://stackoverflow.com/questions/67223446/promise-all-inside-useeffect-in-react-returns-an-array-of-undefined-items
  useEffect(() => {
    if (!isEmpty(dictionaryDataState)) {
      // 0. In useEffect
      // 1. Introduce function for async call
      const fetchReferences = async () => {
        Promise.all(
          Array.isArray(props.publications) && props.publications.length !== 0
            ? props.publications.map(async (item: object) => {
                return createAPAReference(item, dictionaryDataState);
              })
            : ""
        ).then((refs) => {
          // 3. Pass each resolved reference to setReference
          setReference(refs);
        });
      };
      // 2. call async function
      fetchReferences().catch(console.error);
    }
  }, [dictionaryDataState]);

  return references.length > 0 ? (
    <div className="border-b border-b-gray-2 pb-5 mb-5">
      <H2
        innerContent="References for publications"
        additionalClasses="pb-5 "
      />
      <ul className="list-disc list-inside">{references}</ul>
    </div>
  ) : (
    <div />
  );
}

export default ReferencesForPublications;
