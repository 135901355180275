import React from "react";

const TitleTag = ({ children }) => {
  return (
    <h5 className="key" style={{ fontWeight: `bold` }}>
      {children}
    </h5>
  );
};

export default TitleTag;
