import React, { useContext, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import H2 from "../htmlElements/h2";
import { createAPAReference } from "../../helpers/createAPAReference";
import { DictionaryDataState } from "../../context/DictionaryDataContext";

function Acknowledgements(props: any) {
  const [references, setReference] = useState([]);
  const dictionaryDataState = useContext(DictionaryDataState) || [];

  useEffect(() => {
    if (!isEmpty(dictionaryDataState)) {
      // 0. In useEffect
      // 1. Introduce function for async call
      const fetchReferences = async () => {
        Promise.all(
          Array.isArray(props.acknowledgement) &&
            props.acknowledgement.length !== 0
            ? props.acknowledgement.map((work) => {
                return createAPAReference(
                  work.publication,
                  dictionaryDataState
                );
              })
            : ""
        ).then((refs) => {
          // 3. Pass each resolved reference to useEffect
          setReference(refs);
        });
      };
      // 2. call async function
      fetchReferences().catch(console.error);
    }
  }, [dictionaryDataState]);

  return references.length > 0 ? (
    <div className="border-b border-b-gray-2 pb-5 mb-5">
      <H2 innerContent="Acknowledgements" additionalClasses="pb-5 " />
      <ul className="list-disc list-inside">{references}</ul>
    </div>
  ) : (
    <div />
  );
}

export default Acknowledgements;
